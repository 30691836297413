<mat-dialog-content>
  <span kdlI18n label="conflictTitle" matDialogTitle></span>

  <div [style.padding]="'0px 20px 20px 20px'">
    <p>It seems there is a new version for the item you have tried to
      update.</p>

    <p>
      <span>Failed URL: </span>
      <i>{{ data.url }}</i>
    </p>

    <p>Please contact support.</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-raised-button
    color="accent"
    kdlI18n
    label="cancelBtn"
    mat-dialog-close
  ></button>
</mat-dialog-actions>
