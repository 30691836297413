import {ErrorHandler, inject, Injectable} from '@angular/core'
import {ProblemService} from '../services/problem.service'
import {HttpErrorResponse} from '@angular/common/http'
import {MatDialog} from '@angular/material/dialog'
import {
  ConflictDialogComponent
} from '../dialogs/conflict-dialog/conflict-dialog.component'
import {AutoSaveService} from '../services/auto-save.service'
import {
  ProjectConflictDialogComponent
} from '../dialogs/project-conflict-dialog/project-conflict-dialog.component'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly problemService = inject(ProblemService)
  private readonly autoSaveService = inject(AutoSaveService)
  private readonly dialog = inject(MatDialog)

  handleError(error: Error) {
    if (error instanceof HttpErrorResponse && error.status === 409) {
      this.autoSaveService.saving$.next(false)
      this.autoSaveService.changed$.next(false)

      if (error.url.includes('projects') || error.url.includes('files')) {
        ProjectConflictDialogComponent.open(this.dialog)
      } else {
        ConflictDialogComponent.open(this.dialog, {url: error.url})
      }
    } else {
      this.problemService.problems$.next({
        description: 'Unhandled error',
        handled: false,
        error
      })
      // IMPORTANT: Rethrow the error otherwise it gets swallowed
      throw error
    }
  }
}
