import {Component, inject} from '@angular/core'
import {MatIconRegistry} from '@angular/material/icon'
import {DomSanitizer} from '@angular/platform-browser'

@Component({
  selector: 'kdl-root',
  template: `
    <kdl-header class="no-print"></kdl-header>
    <div class="home">
      <router-outlet></router-outlet>
    </div>
    <div class="filler"></div>
    <kdl-footer class="no-print"></kdl-footer>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private readonly iconRegistry = inject(MatIconRegistry)
  private readonly sanitizer = inject(DomSanitizer)

  constructor() {
    this.iconRegistry.addSvgIcon('kdl_price_tag',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icon_price_tag.svg'))
  }
}
